.contact-us-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
}

.contact-us-logo {
    width: 240px;
}

.contact-us-title {
    color: #4C0078;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.form-group label {
    color: 5F22D9;
    margin-bottom: 0.5rem;
}

.form-control {
    border: 2px solid #5F22D9;
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.submit-btn {
    background-color: transparent;
    color: #5F22D9;
    border: 2px solid #5F22D9;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}

.submit-btn:hover {
    background-color: #5F22D9;
    color: gold;
}
