.logo {
    width: 300px;
    height: 200px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
 }

.appstore {
    width: 200;
    height: 150;
    margin-left: auto;
    margin-right: auto;
}

div {
    text-align: center;
}

span {
    align-items: center;
}

.header-item {
    color: white;
    font-size: 20px;
    font-family: 'Outfit', sans-serif;
    position: absolute;
    top: 10px;
    right: 20px;
}

.support-link {
    position: absolute;
    top: 10px;
    right: 20px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 24px;
}

.main {
    background-image: url("share.jpg"); 
    font-family: 'Outfit', sans-serif;
    color: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
}

.bod {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.form {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    color: black;
    font-family: 'Outfit', sans-serif;
    text-align: left;
    align-content: flex-start;
}

.form-field {
    color: black;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    align-items: flex-start;
    text-align: left;
    align-content: flex-start;
    background-color: white;
}
.support-link:hover {
    text-decoration: underline;
}